<template>
  <ProductListSlider
    v-if="!loading"
    :categoryId="categoryId"
    :productList="productList"
    :layout="layout"
    :shuffle="shuffle"
    :promo="promo"
    :limit="limit"
    :title="title"
    :paginationClass="paginationClass"
  />
</template>
<script>
import split from "lodash/split";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import ProductService from "~/service/productService";

export default {
  name: "WpProductListSlider",
  props: {
    categoryId: { type: String, required: false },
    productIds: { type: String, required: false },
    layout: { type: Number, required: false },
    shuffle: { type: Boolean, required: false, default: false },
    promo: { type: Boolean, required: false, default: false },
    limit: { type: Number, required: false, default: 12 },
    title: { type: String, required: false },
    paginationClass: {
      type: String,
      default: "swiper-wp-pagination-slider"
    }
  },

  components: { ProductListSlider },
  data() {
    return {
      productList: [],
      loading: true
    };
  },
  async created() {
    if (this.productIds) {
      //split by comma
      let ids = split(this.productIds, ",");
      let result = await ProductService.getProductsByIds(ids);
      this.productList = result.products;
    } else if (this.categoryId && !this.layout) {
      let result = await ProductService.getProductsByCategoryId(
        this.categoryId
      );
      this.productList = result.products;
    }
    this.loading = false;
  }
};
</script>
