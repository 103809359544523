<template>
  <div class="page">
    <component
      v-if="page"
      :is="templateName"
      :key="key"
      :page="page"
      :additionalData="additionalData"
    ></component>
    <not-found v-else />
  </div>
</template>
<script>
import PageContainer from "@/components/wordpress/PageContainer.vue";
import PageFullWidth from "@/components/wordpress/PageFullWidth.vue";
import clickHandler from "~/mixins/clickHandler";
import page from "~/mixins/page";
import NotFound from "@/views/NotFound.vue";

export default {
  mixins: [clickHandler, page],
  data() {
    return { page: {}, key: 1, additionalData: null };
  },
  components: { PageContainer, PageFullWidth, NotFound },
  computed: {
    templateName() {
      switch (this.page.template) {
        case "page-fullwidth.php":
          return "PageFullWidth";
        default:
          return "PageContainer";
      }
    }
  },
  created() {
    if (this.$route.params.additionalData) {
      this.additionalData = this.$route.params.additionalData;
    }
    // this.fetchPage(this.$route.params.pageName);
    this.page = this.$route.params.page;
  },

  watch: {
    "$route.params.pageName": function() {
      this.page = this.$route.params.page;
      this.key++;
    }
  }
};
</script>
