<template>
  <div :id="containerID"></div>
</template>
<script>
export default {
  name: "javascriptLoader",
  props: {
    containerID: { type: String, default: "custom-js", required: false },
    src: { type: String, required: true }
  },
  data() {
    return {
      template: null
    };
  },
  methods: {
    loadScript() {
      let script = document.createElement("script");
      script.setAttribute("src", this.src);
      script.async = false;

      let htmlEelement = document.getElementById(this.containerID);
      htmlEelement.appendChild(script);
    }
  },
  mounted() {
    try {
      this.loadScript();
    } catch (err) {
      console.log(err);
    }
  }
};
</script>
