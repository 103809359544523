<template>
  <div class="shop-at-work-subscription">
    <v-form ref="form" v-model="isValid" class="d-flex">
      <v-text-field
        v-model="companyCode"
        color="secondary"
        :label="$t('shopAtWork.inputText')"
        outlined
        dense
        :rules="[requiredRules()]"
        required
      ></v-text-field>
      <v-btn
        class="secondary subscribe-button"
        height="40"
        @click="subscribeCompany()"
        large
        depressed
        >{{ $t("shopAtWork.subscribeBtn") }}</v-btn
      >
    </v-form>
  </div>
</template>
<style lang="scss">
.shop-at-work-subscription {
  .v-text-field {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .v-btn {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
</style>
<script>
import cartInfo from "~/mixins/cartInfo";

import DeliveryService from "~/service/deliveryService";

import { requiredValue } from "~/validator/validationRules";

export default {
  name: "ShopAtWorkSubscription",
  mixins: [cartInfo],
  data() {
    return {
      isValid: false,
      companyCode: null,
      requiredRules: requiredValue
    };
  },
  methods: {
    async subscribeCompany() {
      if (this.$refs.form.validate()) {
        // eslint-disable-next-line no-unused-vars
        let res = await DeliveryService.subscribeCompany(this.companyCode);
        // se ok, apro address selector
        this.openAddressSelector();
      }
    }
  }
};
</script>
