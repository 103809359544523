<template>
  <div class="wordpress-dialog text-center">
    <v-dialog v-model="dialog" :width="width">
      <template v-slot:activator="{ on, attrs }">
        <div v-html="activator" v-bind="attrs" v-on="on"></div>
      </template>

      <v-card>
        <v-card-title v-if="title" class="text-h2 grey lighten-2">
          {{ title }}
        </v-card-title>
        <div v-html="content"></div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "WordPressDialog",
  data() {
    return {
      dialog: false
    };
  },
  props: {
    width: { type: String, default: "500" },
    activator: { type: String },
    title: { type: String, default: "" },
    content: { type: String, required: true },
    auto: { type: String, default: "0" }
  },
  created() {
    // Show on init
    if (this.auto == "1") {
      this.dialog = true;
    }
  }
};
</script>
